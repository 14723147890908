import * as React from "react";
import { useEffect } from "react";
import HomeHero from "../components/home/hero";
import Navbar from "../components/global/navbar";
import Footer from "../components/global/Footer";
import Helmet from "react-helmet";
import setupLogRocketReact from "logrocket-react";
import LogRocket from 'logrocket';

const IndexPage = () => {
  // useEffect(() => {
  //   LogRocket.init('kcf5v3/aerocharter');
  //   LogRocket.identify('THE_USER_ID_IN_YOUR_APP', {
  //     name: 'test',
  //     email: 'test@example.com',
  //     subscriptionType: 'pro'
  //   });
  //   setupLogRocketReact(LogRocket)
  //   // This is an example script - don't forget to change it!
  // }, [])
  return (
    <div className="space-y-10 ">
      <Helmet>
        <title>Cotizar y rastrear</title>
      </Helmet>
      <Navbar />
      <div className="bg-white mx-auto max-w-7xl px-6 ">
        <h1 className="base-title text-primary-dark-green">Cotiza y rastrea</h1>
        <HomeHero />
      </div>
      <Footer />
    </div>
  );
};

export default IndexPage;
