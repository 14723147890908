/* This example requires Tailwind CSS v2.0+ */
import { LogoutIcon } from "@heroicons/react/outline";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";

const HomeHero = () => {
  return (
    <div className="bg-white">
      <main>
        <div>
          {/* Hero card */}
          <div className="relative ">
            <div className="absolute inset-x-0 bottom-0" />
            <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
              <div className="relative shadow-xl sm:rounded-2xl sm:overflow-hidden">
                <div className="absolute inset-0">
                  <StaticImage
                    className="h-full w-full object-cover"
                    src="../../images/viva-bg.jpg"
                    alt="Avión de Viva Aerobus"
                  />
                  <div className="absolute inset-0 bg-gray-900 bg-opacity-70 mix-blend-multiply" />
                </div>
                <form
                  className="sm:max-w-lg sm:w-full sm:flex mx-auto"
                  action="/rastreo"
                  method="get"
                >
                  <div className="relative px-6 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
                    <input
                      id="guide-track"
                      name="guia"
                      type="text"
                      className="input-field"
                      placeholder="Ingresa tu número de guía o código de rastreo"
                    />
                    <p className="mt-6 max-w-lg mx-auto text-center text-sm text-white sm:max-w-3xl">
                      Gracias a nuestra alianza con VivaAerobus ponemos a tu
                      disposición nuestra guía de rastreo.
                    </p>
                    <div className="mt-10 max-w-sm mx-auto  flex justify-center">
                      <button className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-primary-light-red hover:bg-opacity-90 sm:px-8">
                        Rastrear{" "}
                        <LogoutIcon
                          className="ml-2 w-6 h-6"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

          {/* cotizar  */}

          <div className="bg-white">
            <div className="mt-10 max-w-sm mx-auto sm:max-w-none sm:flex sm:justify-center px-6">
              <Link
                to="/cotizar-envio"
                className="flex items-center justify-center  px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-primary-light-green hover:bg-opacity-80 sm:px-8"
              >
                Cotizar envío
              </Link>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default HomeHero;
